import { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import Table from '../../../Components/Table'
import Loaders from '../../../loaders/loaders'
import { getMinPrice } from './utils'

const Products = () => {
    const data = useLoaderData()
    const loaders = new Loaders()

    return (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {(res) => (
                    <Table
                        api={loaders.products}
                        withSearch
                        withPagination
                        columns={columns}
                        data={res}
                        path='/store/products'
                        rowModifierFunc={(products) =>
                            products.map((p) => ({ ...p, calculatedPrice: getMinPrice(p) }))
                        }
                    />
                )}
            </Await>
        </Suspense>
    )
}

const columns = [
    {
        name: 'Артикул',
        field: 'article',
    },
    {
        name: 'Название',
        field: 'name',
    },
    {
        name: 'Цена',
        field: 'calculatedPrice',
        type: 'price',
    },
    {
        name: 'Дата обновления',
        field: 'updatedAt',
        type: 'date',
    },
]

export default Products
