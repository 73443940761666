import { createBrowserRouter, RouterProvider, defer } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'

// CSS Modules, react-datepicker-cssmodules.css//
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import {
    Dashboard,
    Login,
    NotFound,
    Customers,
    Store,
    CategoriesMain,
    CategoriesAdd,
    Bundle,
    BundleMain,
    Brand,
    Compilations,
    TagsAddGroup,
    TagsMain,
    BrandsAdd,
    BrandsMain,
    TagsAdd,
    ProductsMain,
    Product,
    Contents,
    SliderMain,
    Slider,
    EventsMain,
    Event,
    TTags,
    Notifications,
    NotificationsTable,
    AddNotification,
    Notification,
    StoriesMain,
    Story,
} from './Pages'

import Loaders from './loaders'

const loaders = new Loaders()

const router = createBrowserRouter([
    {
        path: '/',
        index: true,
        element: <Dashboard />,
        errorElement: <NotFound />,
    },
    {
        path: '/customers',
        element: <Customers />,
    },
    {
        path: '/contents',
        element: <Contents />,
        children: [
            {
                path: 'sliders',
                element: <SliderMain />,
                loader: async () => {
                    return defer({ data: loaders.slider.get() })
                },
                children: [
                    {
                        path: ':id',
                        element: <Slider />,
                        loader: async ({ params: { id } }) => {
                            return defer({ data: loaders.slider.get(id) })
                        },
                    },
                ],
            },
            {
                path: 'events',
                element: <EventsMain />,
                loader: async () => {
                    return defer({ data: loaders.events.get() })
                },
                children: [
                    {
                        path: 'add',
                        element: <Event />,
                    },
                    {
                        path: ':id',
                        element: <Event />,
                        loader: async ({ params: { id } }) => {
                            return defer({ data: loaders.events.get(id) })
                        },
                    },
                ],
            },
            {
                path: 'stories',
                element: <StoriesMain />,
                loader: async () => {
                    return defer({ data: loaders.stories.get() })
                },
                children: [
                    {
                        path: 'add',
                        element: <Story />,
                    },
                    {
                        path: ':id',
                        element: <Story />,
                        loader: async ({ params: { id } }) => {
                            return defer({ data: loaders.stories.get(id) })
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/notifications',
        element: <Notifications />,
        children: [
            {
                path: '',
                element: <NotificationsTable />,
                loader: async ({ request: { url } }) => {
                    // const urlParams = new URLSearchParams(new URL(url).search)

                    // let [limit, offset] = [1000, 0]
                    // if (urlParams.get('page')) {
                    //     const page = parseInt(urlParams.get('page'), 10)
                    //     offset = (page - 1) * limit
                    // }

                    // if (urlParams.get('s')) {
                    //     return defer({
                    //         data: loaders.categories.search(
                    //             urlParams.get('s'),
                    //             limit,
                    //             offset
                    //         ),
                    //     })
                    // }

                    return defer({
                        data: loaders.notifications.get(),
                    })
                },
            },
            {
                path: 'add',
                loader: async () => {
                    return defer({ data: loaders.events.get() })
                },
                element: <AddNotification />,
            },
            {
                path: ':id',
                element: <Notification />,
                loader: async ({ params: { id } }) => {
                    return defer({
                        data: loaders.notifications.get(id),
                    })
                },
            },
        ],
    },
    {
        path: '/store',
        element: <Store />,
        children: [
            {
                path: 'categories',
                element: <CategoriesMain />,
                loader: async ({ request: { url } }) => {
                    const urlParams = new URLSearchParams(new URL(url).search)

                    let [limit, offset] = [1000, 0]
                    if (urlParams.get('page')) {
                        const page = parseInt(urlParams.get('page'), 10)
                        offset = (page - 1) * limit
                    }

                    if (urlParams.get('s')) {
                        return defer({
                            data: loaders.categories.search(urlParams.get('s'), limit, offset),
                        })
                    }

                    return defer({
                        data: loaders.categories.get(limit, offset),
                    })
                },
                children: [
                    {
                        path: 'add',
                        element: <CategoriesAdd />,
                    },
                ],
            },
            {
                path: 'products',
                element: <ProductsMain />,
                loader: async ({ request: { url } }) => {
                    const urlParams = new URLSearchParams(new URL(url).search)

                    let [limit, offset] = [50, 0]
                    if (urlParams.get('page')) {
                        const page = parseInt(urlParams.get('page'), 10)
                        offset = (page - 1) * limit
                    }

                    if (urlParams.get('s')) {
                        return defer({
                            data: loaders.products.search(urlParams.get('s'), limit, offset),
                        })
                    }

                    return defer({ data: loaders.products.get(limit, offset) })
                },
                children: [
                    {
                        path: ':id',
                        element: <Product />,
                        loader: async ({ params: { id } }) => {
                            const brands = loaders.brands.get(100, 0)
                            const tags = loaders.tags.get()
                            const categories = loaders.categories.get()
                            const badges = loaders.badges.get()
                            const tagGroups = loaders.tags.getGroup()
                            return defer({
                                data: loaders.products.getById(id),
                                tagGroups,
                                brands,
                                tags,
                                categories,
                                badges,
                            })
                        },
                    },
                ],
            },
            {
                path: 'brands',
                element: <BrandsMain />,
                loader: async ({ request: { url } }) => {
                    const urlParams = new URLSearchParams(new URL(url).search)

                    let [limit, offset] = [1000, 0]
                    if (urlParams.get('page')) {
                        const page = parseInt(urlParams.get('page'), 10)
                        offset = (page - 1) * limit
                    }

                    if (urlParams.get('s')) {
                        return defer({
                            data: loaders.brands.search(urlParams.get('s'), limit, offset),
                        })
                    }

                    return defer({ data: loaders.brands.get(limit, offset) })
                },
                children: [
                    {
                        path: 'add',
                        element: <BrandsAdd />,
                    },
                    {
                        path: ':id',
                        element: <Brand />,
                        loader: async ({ params: { id } }) => {
                            return defer({ data: loaders.brands.getById(id) })
                        },
                    },
                ],
            },
            {
                path: 'tags',
                element: <TagsMain />,
                loader: async () => {
                    return defer({ data: loaders.tagsGroup.get() })
                },
                children: [
                    {
                        path: 'add/group',
                        element: <TagsAddGroup />,
                    },
                    {
                        path: 'add/tag',
                        element: <TagsAdd />,
                        loader: async ({ params: { id } }) => {
                            const tags = loaders.tagsGroup.get()
                            return defer({ tags })
                        },
                    },
                    {
                        path: ':id',
                        element: <TTags />,
                        loader: async ({ params: { id } }) => {
                            const data = loaders.tags.get(100, 0, id)
                            return defer({ data })
                        },
                    },
                ],
            },
            {
                path: 'bundles',
                element: <BundleMain />,
                loader: async ({ request: { url } }) => {
                    const urlParams = new URLSearchParams(new URL(url).search)

                    let [limit, offset] = [50, 0]
                    if (urlParams.get('page')) {
                        const page = parseInt(urlParams.get('page'), 10)
                        offset = (page - 1) * limit
                    }

                    if (urlParams.get('s')) {
                        return defer({
                            data: loaders.bundles.search(urlParams.get('s'), limit, offset),
                        })
                    }

                    return defer({ data: loaders.bundles.get(limit, offset) })
                },
                children: [
                    {
                        path: ':id',
                        element: <Bundle />,
                        loader: async ({ params: { id } }) => {
                            const brands = loaders.brands.get(100, 0)
                            const tags = loaders.tags.get()
                            const categories = loaders.categories.get()
                            const badges = loaders.badges.get()
                            return defer({
                                data: loaders.bundles.getById(id),
                                brands,
                                tags,
                                categories,
                                badges,
                            })
                        },
                    },
                ],
            },
            {
                path: 'compilations',
                element: <Compilations />,
            },
        ],
    },
    {
        path: '/login',
        index: true,
        element: <Login />,
        errorElement: <NotFound />,
    },
])

const App = () => <RouterProvider router={router} />

export default App
