import { NavLink } from 'react-router-dom'
import classes from './Aside.module.css'
import clsx from 'clsx'

const Aside = () => {
    return (
        <aside className={classes.aside}>
            <div className={classes.aside__logo}>
                <strong>Parfumer Atelier</strong>
            </div>
            <nav className={classes.aside__content} role='navigation'>
                {links.map((l, i) => (
                    <NavLink
                        key={`aside_link_${i}`}
                        className={({ isActive }) =>
                            clsx(classes.nav__link, {
                                [classes.nav__link__active]: isActive,
                            })
                        }
                        to={l.link}
                        end={l.link === '/' ? true : false}>
                        {l.name}
                    </NavLink>
                ))}
            </nav>
            <div className={classes.aside__footer}>
                <small>Powered by Clockwork</small>
            </div>
        </aside>
    )
}

const links = [
    {
        link: '/',
        name: 'Главная',
    },
    {
        link: '/customers',
        name: 'Клиенты',
    },
    {
        link: '/orders',
        name: 'Заказы',
    },
    {
        link: '/store',
        name: 'Магазин',
    },
    {
        link: '/notifications',
        name: 'Push-уведомления',
    },
    {
        link: '/terminals',
        name: 'Торговые точки',
    },
    {
        link: '/contents',
        name: 'Контент',
    },
    {
        link: '/settings',
        name: 'Настройки',
    },
]

export default Aside
