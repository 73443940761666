import {Await, useLoaderData} from "react-router-dom"
import Select from "react-select"
import {createRef, Suspense, useCallback, useEffect, useRef, useState} from "react"
import classes from "../../../common.module.css"
import {useDropzone} from "react-dropzone"
import Loaders from "../../../loaders"
import DeleteIcon from "../../../assets/delete.svg"
import PlusIcon from "../../../assets/plus.svg"
import clsx from "clsx"
import {toast, ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Editor} from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js"
import draftToHtml from "draftjs-to-html"

const Bundle = () => {

    const data = useLoaderData()

    const [product, setProduct] = useState(null)
    const [editor, setEditor] = useState(EditorState.createEmpty())
    const [brands, setBrands] = useState(null)
    const [tags, setTags] = useState(null)
    const [tagGroups, setTagGroups] = useState(null)
    const [selectedTagGroups, setSelectedTagGroups] = useState({})
    const [categories, setCategories] = useState(null)
    const [badges, setBadges] = useState(null)

    const loaders = new Loaders()

    const refs = useRef(Array.from({length: 20}, () => createRef()))
    const gridRef = useRef(null)
    const toastId = useRef(null)

    useEffect(() => {
        if (product && product.description)
            setEditor(
                EditorState.createWithContent(
                    ContentState.createFromBlockArray(convertFromHTML(product.description))
                )
            )

        if (product && product.tags) {
            const groupedTag = []

            for (let i of product.tags) {
                if (!groupedTag.includes(i.groups[0]))
                    groupedTag.push(i.groups[0])
            }

            let obj = {}
            groupedTag.map((t, i) => {
                obj[`id${i}`] = {group: t, tags: [], availableTags: []}
            })

            groupedTag.map((t, i) => {
                const filteredTag = product.tags.filter((tag) => tag.groups.includes(t))
                loaders.tags.get(100, 0, t)
                    .then((tags) => {
                        let bs = []
                        for (let i of tags) {
                            bs.push({value: i._id, label: i.name})
                        }
                        obj[`id${i}`] = {group: t, tags: filteredTag.map((p) => p._id), availableTags: bs}
                        setSelectedTagGroups(obj)
                    }).catch((err) => {
                        console.log(err)
                    })
            })
        }
    }, [product])

    const onDragStart = (e) => {
        e.currentTarget.classList.add("selected")
    }

    const onDragEnd = (e) => {
        e.currentTarget.classList.remove("selected")
    }

    const getNextEl = (position, el) => {
        const coords = el.getBoundingClientRect()
        const center = coords.x + coords.width / 2
        return (position < center) ? el : el.nextElementSibling
    }

    const onDragOver = (e) => {
        e.preventDefault()
        const active = refs.current.find(el => el.current.classList.contains("selected"))
        const current = e.currentTarget
        const isMovable = active !== current && current.classList.contains("image")

        if (!isMovable) {
            return false
        }

        const next = getNextEl(e.clientX, current)

        if (next && (active.current === next.previousElementSibling || active.current === next)) {
            return false
        }

        if (gridRef.current) {
            gridRef.current.insertBefore(active.current, next)
        }
    }

    const onDrop = useCallback(acceptedFiles => {
        loaders.products.setImage(product._id, acceptedFiles[0])
            .then((data) => {
                const urls = product.imgUrl
                urls.push(data.imgUrl)
                setProduct({...product, imgUrl: urls})
            }).catch((err) => {
            console.log(err)
        })
    }, [product])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const prepareValues = (b, func) => {
        let bs = []
        for (let i of b) {
            bs.push({value: i._id, label: i.name})
        }

        func(bs)
    }

    const defaultValues = (key, arr) => {
        if (arr && product && Array.isArray(product[key])) {
            const a = arr.filter((b) => {
                return product[key].some((f) => f._id === b.value)
            })
            return a
        }
        return  []
    }

    const setBundleValue = (key, value) => {
        setProduct({...product, [key]: value})
    }

    const onBrandChange = ({value}) => {
        setProduct({...product, brands: [value]})
    }

    const onTagsChange = (id, tags) => {
        setSelectedTagGroups({...selectedTagGroups, [id]: {group: selectedTagGroups[id].group, availableTags: selectedTagGroups[id].availableTags, tags: tags.map(b => b.value)}})
    }

    const onTagGroupsChange = (id, value) => {
        loaders.tags.get(100, 0, value)
            .then((tags) => {
                let bs = []
                for (let i of tags) {
                    bs.push({value: i._id, label: i.name})
                }
                setSelectedTagGroups({...selectedTagGroups, [id]: {group: value, tags: [], availableTags: bs}})
            }).catch((err) => {
                console.log(err)
            })
    }

    const onBadgesChange = (badges) => {
        setProduct({...product, badges: badges.map(b => b.value)})
    }

    const onCategoryChange = ({value}) => {
        setProduct({...product, categories: [value]})
    }

    const onSave = (e) => {
        e.preventDefault()
        toastId.current = toast("Обновляем продукт", { autoClose: false })

        const newImgUrls = []
        if (gridRef.current) {
            for (let child of gridRef.current.children) {
                const url = child.dataset.url
                if (url) {
                    newImgUrls.push(url)
                }
            }
        }

        product.priceDefault = parseInt(product.priceDefault, 10)
        product.imgUrl = newImgUrls
        product.description = draftToHtml(convertToRaw(editor.getCurrentContent()))

        let tags = []
        Object.entries(selectedTagGroups).map((e) => {
            tags = [...tags, ...e[1].tags]
        })

        product.tags = tags

        loaders.products.update(product)
            .then(() => {
                toast.update(toastId.current, { render: "Продукт обновлен", type: toast.TYPE.INFO, autoClose: 3000 })
            }).catch((err) => {
                console.log(err)
                toast.update(toastId.current, { render: "Не удалось обновить продукт", type: toast.TYPE.ERROR, autoClose: 3000 })
            })
    }

    const onChangeModifier = (e, i, param) => {
        const { target: { value } } = e
        let modifiers = product.modifiers
        modifiers[i][param] = param !== "name" ? parseFloat(value) : value
        setProduct({...product, modifiers})
    }

    const onChangeComponents = (e, i) => {
        const { target: { value } } = e
        let components = product.components
        components[i].name = value
        setProduct({...product, components})
    }

    const onDelete = (e, url) => {
        e.preventDefault()
        toastId.current = toast("Удаляем изображение", { autoClose: false })
        setProduct({...product, imgUrl: product.imgUrl.filter(u => u !== url)})
        loaders.bundles.deleteImage(product._id, url)
            .then(() => {
                toast.update(toastId.current, { render: "Изображение удалено", type: toast.TYPE.INFO, autoClose: 3000 })
            }).catch((err) => {
                console.log(err)
                toast.update(toastId.current, { render: "Не удалось удалить изображение", type: toast.TYPE.ERROR, autoClose: 3000 })
            })
    }

    const addTagGroup = (e) => {
        e.preventDefault()
        let count = Object.entries(selectedTagGroups).length
        setSelectedTagGroups({...selectedTagGroups, [`id${count}`]: {}})
    }

    return (product === null || brands === null || badges === null || tags === null || categories === null || tagGroups === null) ? (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {
                    ({ data, brands, tags, categories, badges, tagGroups }) => {
                        Promise.resolve(brands).then((b) => prepareValues(b, setBrands))
                        Promise.resolve(badges).then((b) => prepareValues(b, setBadges))
                        Promise.resolve(tags).then((b) => prepareValues(b, setTags))
                        Promise.resolve(categories).then((b) => prepareValues(b, setCategories))
                        Promise.resolve(data).then((b) => setProduct(b.values[0]))
                        Promise.resolve(tagGroups).then((b) => prepareValues(b, setTagGroups))
                    }
                }
            </Await>
        </Suspense>
    ) : (
        <>
            <ToastContainer />
            <h4>Название</h4>
            <input type="text" value={product && product.name} onChange={({target:{value}}) => setBundleValue("name", value)}/>

            <h4>Описание</h4>
            <Editor
                editorState={editor}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                localization={{
                    locale: 'ru',
                }}
                onEditorStateChange={(e) => setEditor(e)}
            />

            {
                product && product.modifiers && product.modifiers.length > 0 ? (
                    <>
                        <h4>Модификаторы</h4>
                        <div>
                            {
                                product.modifiers.map((m, i) => (
                                    <div key={m.article} className={classes.modifier}>
                                        <div>
                                            <label>Название</label>
                                            <input type="text" placeholder="Название" value={m.name} onChange={(e) => onChangeModifier(e, i, "name")} />
                                        </div>
                                        <div>
                                            <label>Цена</label>
                                            <input type="text" placeholder="Цена" value={m.priceDefault} onChange={(e) => onChangeModifier(e, i, "priceDefault")} />
                                        </div>
                                        <div>
                                            <label>Цена cо скидкой</label>
                                            <input type="text" placeholder="Цена" value={m.priceSale} onChange={(e) => onChangeModifier(e, i, "priceSale")} />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                ) : null
            }

            {
                product && product.components && product.components.length > 0 ? (
                    <>
                        <h4>Компоненты</h4>
                        <div>
                            {
                                product.components.map((m, i) => (
                                    <div key={m.article} className={classes.modifier}>
                                        <div>
                                            <label>Название</label>
                                            <input type="text" placeholder="Название" value={m.name}
                                                   onChange={(e) => onChangeComponents(e, i)}/>
                                        </div>
                                        <div>
                                            <label>Количество</label>
                                            <input type="text" placeholder="Количество" defaultValue={m.quantity}
                                                   disabled/>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <h4>Цена</h4>
                        <div>
                            <label>Цена</label>
                            <input type="text" placeholder="Цена" value={product && product.priceDefault} onChange={({target:{value}}) => setBundleValue("priceDefault", value)}/>
                        </div>
                    </>
                ) : null
            }

            <div className={classes.row}>
                <h4>Тэги</h4>
                <button className={classes.btn} onClick={addTagGroup}>Добавить группу тэгов</button>
                {
                    Object.entries(selectedTagGroups).map((g, i) => (
                        <div key={`id${i}`} className={classes.col2}>
                            <div>
                                <label>Группа тэгов</label>
                                <Select defaultValue={tagGroups.find(g => g.value === selectedTagGroups[`id${i}`].group)} onChange={({value}) => onTagGroupsChange(`id${i}`, value)} isSearchable isLoading={ tagGroups === null } options={tagGroups} />
                            </div>
                            <div>
                                <label>Тэги</label>
                                <Select defaultValue={selectedTagGroups[`id${i}`].availableTags && selectedTagGroups[`id${i}`].availableTags.filter(t => selectedTagGroups[`id${i}`].tags.includes(t.value))} data-group={`id${i}`} onChange={(value) => onTagsChange(`id${i}`, value)} isDisabled={!selectedTagGroups.hasOwnProperty(`id${i}`)} isMulti isSearchable isLoading={ !selectedTagGroups[`id${i}`].availableTags } options={selectedTagGroups[`id${i}`].availableTags} />
                            </div>
                        </div>
                    ))
                }
            </div>

            <h4>Бренд</h4>
            <Select onChange={onBrandChange} isSearchable isLoading={ brands.length === 0 } options={brands} defaultValue={product && product.brands[0] && brands.find((b) => b.value === product.brands[0]._id)} />

            <h4>Категории</h4>
            <Select onChange={onCategoryChange} isSearchable isLoading={ categories.length === 0 } options={categories} defaultValue={product && product.categories[0] && categories.find((b) => b.value === product.categories[0]._id)} />

            <h4>Баджи</h4>
            <Select onChange={onBadgesChange} isMulti isSearchable isLoading={ badges.length === 0 } options={badges} defaultValue={defaultValues("badges", badges)} />

            <h4>Изображения<br/><small>Максимум 20 изображений. Первое изображение будет превью</small></h4>
            <div ref={gridRef} className={classes.imagesGrid}>
                {
                    product && product.imgUrl.map((img, i) => (
                        <div
                            key={`img_${i}`}
                            ref={refs.current[i]}
                            draggable
                            data-url={img}
                            className="image"
                            onDragOver={(e) => onDragOver(e)}
                            onDragEnd={onDragEnd}
                            onDragStart={onDragStart}
                        >
                            <img src={img} onDragStart={() => false} alt={i.toString()} />
                            <button onClick={(e) => onDelete(e, img)}>
                                <img src={DeleteIcon} alt="Удалить"/>
                            </button>
                        </div>
                    ))
                }
                <div {...getRootProps()} className={clsx(classes.dropzone, {[classes.dragActive]: isDragActive})}>
                    <input {...getInputProps()} />
                    <img src={PlusIcon} alt="Добавить изображение" />
                </div>
            </div>

            <button onClick={onSave} className={classes.btn}>Сохранить</button>
        </>
    )
}

export default Bundle